var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "header-container" },
    [
      _c("TaskManagerConfigTaskModal", {
        attrs: {
          "show-modal": _vm.showConfigTaskModal,
          "tasks-scheduler": _vm.tasksScheduler,
        },
        on: {
          "update:showModal": function ($event) {
            _vm.showConfigTaskModal = $event
          },
          "update:show-modal": function ($event) {
            _vm.showConfigTaskModal = $event
          },
          "update:tasksScheduler": function ($event) {
            _vm.tasksScheduler = $event
          },
          "update:tasks-scheduler": function ($event) {
            _vm.tasksScheduler = $event
          },
        },
      }),
      _c("TaskManagerInconsistencyAlertModal", {
        attrs: {
          "show-modal": _vm.showInconsistencyAlertModal,
          "tasks-scheduler": _vm.tasksScheduler,
        },
        on: {
          "update:showModal": function ($event) {
            _vm.showInconsistencyAlertModal = $event
          },
          "update:show-modal": function ($event) {
            _vm.showInconsistencyAlertModal = $event
          },
          "update:tasksScheduler": function ($event) {
            _vm.tasksScheduler = $event
          },
          "update:tasks-scheduler": function ($event) {
            _vm.tasksScheduler = $event
          },
        },
      }),
      _c("p", { staticClass: "grey300" }, [
        _vm._v(" " + _vm._s(_vm.$t("taskManager.header.title")) + " "),
      ]),
      _c("div", { staticClass: "flex justify-between" }, [
        _c(
          "div",
          { staticClass: "flex gap-4 items-center" },
          [
            _c("Button", {
              staticClass: "primary",
              attrs: {
                icon: "icon-r-circle-plus",
                label: _vm.$t("taskManager.header.button"),
              },
              on: {
                click: function ($event) {
                  _vm.showConfigTaskModal = true
                },
              },
            }),
            _c("Button", {
              staticClass: "outlined p-button-raised",
              attrs: {
                icon: "icon-r-mailing",
                label: _vm.$t("taskManager.header.inconsistencyAlertButton"),
              },
              on: {
                click: function ($event) {
                  _vm.showInconsistencyAlertModal = true
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex gap-4 items-center" },
          [
            _vm.showTable
              ? _c("Caption", { attrs: { captions: _vm.tableCaptions } })
              : _c("Caption", { attrs: { captions: _vm.calendarCaptions } }),
            _c("div", { staticClass: "flex justify-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn-icon left w-8 h-8",
                  class: { "icon--focus": _vm.showCalendar },
                  on: { click: _vm.handleShowCalendar },
                },
                [_c("i", { staticClass: "icon-r-timetable w-4 h-4" })]
              ),
              _c(
                "button",
                {
                  staticClass: "btn-icon right w-8 h-8",
                  class: { "icon--focus": _vm.showTable },
                  on: { click: _vm.handleShowTable },
                },
                [_c("i", { staticClass: "icon-r-menu2 w-4 h-4" })]
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }